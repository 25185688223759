<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="#54A5C8"
    />
    <path d="M7.905 16.132C7.19367 16.132 6.585 15.967 6.079 15.637C5.573 15.2997 5.18067 14.834 4.902 14.24C4.63067 13.6387 4.495 12.942 4.495 12.15C4.495 11.3653 4.63067 10.6723 4.902 10.071C5.18067 9.46967 5.573 9.004 6.079 8.674C6.585 8.33667 7.19367 8.168 7.905 8.168C8.61633 8.168 9.225 8.33667 9.731 8.674C10.237 9.004 10.6257 9.46967 10.897 10.071C11.1757 10.6723 11.315 11.3653 11.315 12.15C11.315 12.942 11.1757 13.6387 10.897 14.24C10.6257 14.834 10.237 15.2997 9.731 15.637C9.225 15.967 8.61633 16.132 7.905 16.132ZM7.905 14.867C8.48433 14.867 8.95733 14.625 9.324 14.141C9.69067 13.657 9.874 12.9933 9.874 12.15C9.874 11.3067 9.69067 10.643 9.324 10.159C8.95733 9.675 8.48433 9.433 7.905 9.433C7.31833 9.433 6.84167 9.675 6.475 10.159C6.11567 10.643 5.936 11.3067 5.936 12.15C5.936 12.9933 6.11567 13.657 6.475 14.141C6.84167 14.625 7.31833 14.867 7.905 14.867Z" fill="black" />
    <rect
      x="14"
      y="8"
      width="7"
      height="2"
      rx="1"
      fill="white"
    />
    <rect
      x="14"
      y="11"
      width="7"
      height="5"
      rx="1"
      fill="white"
    />
  </svg>
</template>
